import Glide from '@glidejs/glide';
var mqListener = require('media-query-listener');

(function(){
    'use strict';

    // news articles
    var $grid = $('.news-cards__wrapper');
    // news article count
    var count =  $grid.children().length;
    // arrows
    var $arrowright = $('.glide__arrow--right');
    var $arrowleft = $('.glide__arrow--left');

    /* createSlider
     * Uses the GlideJS library to create a slider 
     * The visbility of the arrows is not handled by the library, it's handled here 
     * mediaQUeryListener library used to detect breakpoints in order to switch the slider on and off */
    (function createSlider() {
        // glideJS config
        var sliderSettings = {
            gap: 20,
            rewind: false,
            perView: 1,
            peek: {before: 0, after: (95+20)},
            breakpoints: {
                600: {
                    perView: 1
                },
                850: {
                    perView: 2
                },
                1025: {
                    perView: 3
                }
            }
        }

        // HTML markup has 5 bullets; 
        // remove bullets based on news article count
        $('.glide__bullet').each(function(index) {
            if (index >= count) $(this).addClass('glide__bullet--hidden'); 
        });
        
        if (!$('.news-cards.glide').length) return; 
        
        // Create slider
        let slider = new Glide('.glide', sliderSettings);

        // control arrow visibility based on current slide
        slider.on('run.after', updateSliderArrows);

        // media query listener
        // destroys the slider for large viewport
        // viewport name set in :after of the body tag
        mqListener.on('large', function(e) {
            slider.destroy();
        });

        // media query listener
        // creates the slider for small viewport
        // viewport name set in :after of the body tag
        mqListener.on('small', function(e) {
            // must create new slider each time after destroy() since destroy can only be called once per slider
            slider = new Glide('.glide',sliderSettings);

            // reset
            slider.on('run.after', updateSliderArrows);
            $arrowleft.addClass('glide__arrow--hidden');
            $arrowright.removeClass('glide__arrow--hidden');

            // initialise slider
            slider.mount();
        });

        // Helper function; Update the visibility of the 
        // slider arrows based on the current slide
        function updateSliderArrows(cfg) {
            if (slider.index == 0) { //first slide
                $arrowright.removeClass('glide__arrow--hidden');
                $arrowleft.addClass('glide__arrow--hidden');
            }
            else if (slider.index == (count-1)) { //last slide
                $arrowleft.removeClass('glide__arrow--hidden');
                $arrowright.addClass('glide__arrow--hidden');
            }
            else { //middle slides
                $arrowright.removeClass('glide__arrow--hidden');
                $arrowleft.removeClass('glide__arrow--hidden');
            }
        }

    }());

    /* configureNewsGrid
     * This function applies a wrapper class and some child classes 
     * to the news cards on the homepage. The news card classnames dictate
     * the positioning of the content inside the card. The width and height 
     * of each card is specified via :nth-child(x) selectors, not these classnames.
     * The wrapper class, along with :nth-child(x) determines the positioning of each card
     * in the grid.
     * card--full-width creates a bigger image with more margin 
     * card--horizontal places the image to the left, text to the right */
    (function configureNewsGrid() {
        //maps # of news articles to the correct parent and child classnames
        var obj = {
            5: {
                parentClass: 'news-cards__wrapper--five',
                childClass: ['card--full-width card--horizontal', 'card--horizontal','', '', 'card--horizontal']
            },
            4: {
                parentClass: 'news-cards__wrapper--four',
                childClass: ['card--horizontal', '', '', 'card--horizontal']
            },
            3: {
                parentClass: 'news-cards__wrapper--three',
                childClass: ['card--full-width card--horizontal', 'card--horizontal','card--horizontal']
            },
        };

        if ($grid.length) {
            $grid.addClass(obj[count].parentClass);
            var classnames = obj[count].childClass;
            $grid.children().each(function(index) {
                $(this).addClass(classnames[index]);
            });
        }
    }());
    
}());
