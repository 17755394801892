(function(){
    'use strict';
    

    // Global variables

    var squiz = {
        'fn': {},
        'vars': {}
    };


    // Functions

    // https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
    // Check if the user's browser is IE
    var browserIsIE = squiz.fn.browserIsIE = function() {
        return navigator.userAgent.toUpperCase().indexOf("TRIDENT/") != -1 || navigator.userAgent.toUpperCase().indexOf("MSIE") != -1;
    }

    // https://panzerstadt.github.io/prng/Misc/20190701_objectfit-wordaround/
    // Converts images using object-fit to background-images in IE browsers
    squiz.fn.objectFitIEPolyfill = function() {
        if (browserIsIE()) {
            var $imgContainers = $('.img_wrapper');
            $imgContainers.each(function() {
                var $container = $(this),
                imgUrl = $container.find('img').prop('src');
                if (imgUrl) {
                $container
                    .css('backgroundImage', 'url(' + imgUrl + ')')
                    .addClass('compat-object-fit')
                }
            });
        }
    };

    squiz.fn.objectFitIEPolyfill();

    // Make squiz variable available to the console for debugging

    window.squiz = squiz;
}());
