import $ from 'jquery';
(function(){
    'use strict';
    
    function closeMobileMenu() {
        $('.hamburger-btn').removeClass('active');
        $('.mobile-menu').removeClass('active');

        var activeElements = $('.mobile-menu .active');
        activeElements.addClass('inactive');
        activeElements.removeClass('active');
        activeElements.siblings('.mobile-menu__expand').attr('aria-expanded', 'false');

        setTimeout(function () {
            $('.mobile-menu').addClass('inactive');
            // $('.mobile-menu').css('position','fixed'); //prevents invisible menu taking up space offscreen
        }, 250);
    }

    function closeSearch() {
        if ($('.site-header.open-search').length > 0) {
            $('.site-header').removeClass('open-search');
        }
        if ($('main.search-open').length > 0) {
            toggleSearch();
        }
    }

    $('.search-opener').on('click', function (event) {
        event.preventDefault()
        // if menu open, close
        if ($('.mobile-menu.active').length > 0) {
            closeMobileMenu();
        }

        toggleSearch();

    })

    $('.search-btn').on('click', function (e) {
        e.preventDefault();

        var isMenuActive = $('.mobile-menu').hasClass('active');
        if (isMenuActive) {
            closeMobileMenu();
        }
        $('.site-header').toggleClass('open-search')
    });

    // close search overlay if click on overlay (not including the search input)
    $('.search_input').on('click', function (event) {
        event.stopPropagation();
    });
    $('.header-search').on('click', function () {
        if ($('main').hasClass('search-open') || !($('.header-search').hasClass('active'))) {
            $('.header-search').toggleClass('active');
            $('.search-opener .contact-link').toggleClass('search-active');
            $('main').toggleClass('search-open');
            $('.search_input').focus();
            $('.search_input').attr('autofocus', 'true');
        }
    })

    $('.hamburger-btn').on('click', function (e) {
        closeSearch();

        $(e.target).toggleClass('active');

        var isActive = $('.mobile-menu').hasClass('active');
        if (!isActive) {
            $('.mobile-menu').addClass('active');
            $('.mobile-menu').removeClass('inactive');

        } else {
            closeMobileMenu();
        }
    });

    //Close language menu on click of anything else
    if ($('.language-menu').length > 0) {
        $(window).click(function (event) {
            if ($('.language-menu .active')) {
                if ($(event.target).closest('.language-menu').length === 0) {
                    $('.language-menu').removeClass('active');
                }
            }
        });
    }
    
    //Search button open/close
    $(document).on('click', '.mob_search_link', function(event) {
        event.preventDefault();
        app.openSearch(this);
    });

    // change search input placeholder text on focus
    $('.search_input.query').on('focus', function () {
        $(this).attr('placeholder', 'eg. Pay bill')
    });
    $('.search_input.query').on('blur', function () {
        $(this).attr('placeholder', 'Search')
    });

    // close global msg alert
    // $('.msg_close').on('click', () => {
    //     $('#alert-msg').css('display', 'none');
    // })

    function toggleSearch() {
        $('.header-search, .header-search--nav').toggleClass('active');
        $('.search-opener .contact-link').toggleClass('search-active');

        $('main').toggleClass('search-open');
        $('.search_input').focus();
        $('.search_input').attr('autofocus', 'true');
    }
}());

