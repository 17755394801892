import $ from 'jquery';
(function(){
    'use strict';

    if ($('.mobile-menu').length > 0) {
        //Find menu links which have a sub menu
        $('.mobile-menu__expand ~ .mobile-menu__sub').each(function (index, element) {
            //Go back to the link and add a class to show there are child elements
            $(element).siblings('.mobile-menu__expand').attr('aria-expanded', 'false');
            $(element).parent().addClass('mobile-menu__item--has-children');
        })

        //Open next panel
        $('.mobile-menu__expand').on('click', function (event) {
            var target = $(event.target);
            target.attr('aria-expanded', 'true');

            var subMenu = target.siblings('.mobile-menu__sub');
            subMenu.removeClass('inactive');
            subMenu.addClass('active');
        });

        //Close panel
        $('.mobile-menu__back-link').on('click', function (event) {
            var target = $(event.target);
            var subMenu = target.closest('.mobile-menu__sub');
            subMenu.removeClass('active');
            
            setTimeout(function () {
                subMenu.addClass('inactive');
            }, 250);

            var expand = subMenu.siblings('.mobile-menu__expand').attr('aria-expanded', 'false');
        });

    }

}());
